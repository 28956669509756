import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Image from 'next/image'
import styles from '../LogoSlider/logoSlider.module.scss'

const LogoSlider = () => {
  return (
    <Fragment>

      {/* <Container
        className={styles.custom_container}
        style={{ minWidth: '100%!important' }}
      > */}
      <Container
        className={styles.custom_container}
        style={{ minWidth: '100%!important' }}
      >
        <Row className={styles.logoSlider}>
          <Col className={styles.logoSliderheading} style={{padding: '0'}}>
            <h2
              className="pt-4 mt-4"
              style={{
                color: 'black',
                textAlign: 'center',
              }}
            > 
              OUR STUDENTS HAVE BEEN ADMITTED TO:
            </h2>
            <Row>
              <Col className={styles.logoSliderimages}>
                <span className={styles.brandLogoDesktop}>
                  <Image
                    src="/WebLogos-April-10.png"
                    alt="university"
                    width={1900}
                    height={551}
                  />
                </span>
                <span className={styles.brandLogoMobile}>
                  <Image
                    src="/Mobile-logos10-4.png" //mobile-view-logo.png - 259
                    alt="Picture of the university Logo"
                    width={560}
                    height={309}
                  />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* </Container> */}
    </Fragment>
  )
}

export default LogoSlider
